const adaptSemobaeAPI = () => {
    window.semobaeAPI = {};
    window.semobaeAPI.receive = (originChannel, callback) => {
        const listener = (event) => {
            const { channel, value } = event.data;
            if (channel === originChannel) {
                callback(event, value);
            }
        }
        window.addEventListener('message', listener);

        return () => {
            window.removeEventListener('message', listener);
        };
    }
    window.semobaeAPI.receiveOnce = (originChannel, callback) => {
        const listener = (event) => {
            const { channel, value } = event.data;
            if (channel === originChannel) {
                callback(event, value);
                window.removeEventListener('message', listener);
            }
        }
        window.addEventListener('message', listener);

        return () => {
            window.removeEventListener('message', listener);
        };
    }
    window.semobaeAPI.send = (channel, ...params) => {
        parent.postMessage({ channel, args: params }, '*');
    }
    window.semobaeAPI.requestExecuteSemobaeExtension = (extensionName) => {
        parent.postMessage({ channel: 'requestExecuteSemobaeExtension', args: [extensionName] }, '*');
    }
    window.semobaeAPI.writeStudyFile = (filename, arraybuffer) => {
        console.log('writeStudyFile', filename, arraybuffer);
        parent.postMessage({ channel: 'writeStudyFile', args: [filename, arraybuffer] }, '*');
    }
    window.semobaeAPI.readStudyFile = (filename) => {
        console.log('readStudyFile', filename);
        parent.postMessage({ channel: 'readStudyFile', args: [filename] }, '*');
    } 
    window.semobaeAPI.getSources = () => {
        console.error('getSources is not supported on web');
    }
    window.semobaeAPI.openExternal = () => {
        console.error('openExternal is not supported on web');
    }
    window.semobaeAPI.downloadMediastoreFile = (filename, base64StringData) => {
        const params = {
            filename,
            content: base64StringData,
        };

        parent.postMessage({ channel: 'downloadMediastore', args: params }, '*');
    }
    window.semobaeAPI.environment = 'web';
}

export default adaptSemobaeAPI;
